
@keyframes typing-flicker {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
.typing-cursor{
    display: inline-block;
  width: 1ch;
  animation: typing-flicker 0.5s infinite;
  margin-bottom: 4px;
}